import React, { Component } from "react";
import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import { buildClientSchema, getIntrospectionQuery, parse } from "graphql";

import { makeDefaultArg, getDefaultScalarArgValue } from "./CustomArgs";

import "graphiql/graphiql.css";
import "./App.css";
import StageTenantSelector from "./StageTenantSelector";

function createFetcher(stage, tenant, token) {
  return function fetcher(requestBody) {
    return fetchQuery(stage, tenant, token, requestBody)
      .then(function(response) {
        return response.text();
      })
      .then(function(responseBody) {
        try {
          return JSON.parse(responseBody);
        } catch (e) {
          return responseBody;
        }
      });
  };
}

function fetchQuery(stage, tenant, token, requestBody) {
  return fetch(
    `https://${tenant}.${stage}.newstore.net/api/v1/org/data/query`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }
  );
}

const DEFAULT_QUERY = ``;

class App extends Component {
  _graphiql;
  state = { schema: null, query: DEFAULT_QUERY, explorerIsOpen: true };

  fetchSchema(stage, tenant, token) {
    fetchQuery(stage, tenant, token, { query: getIntrospectionQuery() })
      .then((response) => {
        if (!response.ok) {
          throw Error(`${response.status} - ${response.statusText}`);
        }
        return response;
      })
      .then((response) => {
        return response.text();
      })
      .then((responseBody) => {
        return JSON.parse(responseBody);
      })
      .then((result) => {
        const editor = this._graphiql.getQueryEditor();
        editor.setOption("extraKeys", {
          ...(editor.options.extraKeys || {}),
          "Shift-Alt-LeftClick": this._handleInspectOperation,
        });

        this.setState({
          ...this.state,
          stage,
          tenant,
          token,
          schema: buildClientSchema(result.data),
        });
      })
      .catch((e) => {
        this.setState({ ...this.state, fetchError: e.message });
      });
  }

  _handleInspectOperation = (cm, mousePos) => {
    const parsedQuery = parse(this.state.query || "");

    if (!parsedQuery) {
      console.error("Couldn't parse query document");
      return null;
    }

    var token = cm.getTokenAt(mousePos);
    var start = { line: mousePos.line, ch: token.start };
    var end = { line: mousePos.line, ch: token.end };
    var relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end),
    };

    var position = relevantMousePos;

    var def = parsedQuery.definitions.find((definition) => {
      if (!definition.loc) {
        console.log("Missing location information for definition");
        return false;
      }

      const { start, end } = definition.loc;
      return start <= position.start && end >= position.end;
    });

    if (!def) {
      console.error(
        "Unable to find definition corresponding to mouse position"
      );
      return null;
    }

    var operationKind =
      def.kind === "OperationDefinition"
        ? def.operation
        : def.kind === "FragmentDefinition"
        ? "fragment"
        : "unknown";

    var operationName =
      def.kind === "OperationDefinition" && !!def.name
        ? def.name.value
        : def.kind === "FragmentDefinition" && !!def.name
        ? def.name.value
        : "unknown";

    var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

    var el = document.querySelector(selector);
    el && el.scrollIntoView();
  };

  _handleEditQuery = (query) => this.setState({ query });

  _handleToggleExplorer = () => {
    this.setState({ explorerIsOpen: !this.state.explorerIsOpen });
  };

  render() {
    const { query, schema } = this.state;
    return (
      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={this._handleEditQuery}
          onRunOperation={(operationName) =>
            this._graphiql.handleRunQuery(operationName)
          }
          explorerIsOpen={this.state.explorerIsOpen}
          onToggleExplorer={this._handleToggleExplorer}
          getDefaultScalarArgValue={getDefaultScalarArgValue}
          makeDefaultArg={makeDefaultArg}
        />
        <GraphiQL
          ref={(ref) => (this._graphiql = ref)}
          fetcher={createFetcher(
            this.state.stage,
            this.state.tenant,
            this.state.token
          )}
          schema={schema}
          query={query}
          onEditQuery={this._handleEditQuery}
          response={this.state.fetchError}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={() => this._graphiql.handlePrettifyQuery()}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button
              onClick={() => this._graphiql.handleToggleHistory()}
              label="History"
              title="Show History"
            />
            <GraphiQL.Button
              onClick={this._handleToggleExplorer}
              label="Explorer"
              title="Toggle Explorer"
            />
            <StageTenantSelector
              fetchSchema={(stage, tenant, token) => {
                this.fetchSchema(stage, tenant, token);
              }}
            />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </div>
    );
  }
}

export default App;
