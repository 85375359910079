import React, { Component } from "react";
import GraphiQL from "graphiql";

interface Props {
  fetchSchema: (stage: string, tenant: string, token: string) => void;
}

interface State {
  stage: string;
  tenant: string;
  token: string;
}

class StageTenantSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      stage: "x",
      tenant: "",
      token: "",
    };
  }

  handleTenantChange = (e: HTMLInputElement) => {
    this.setState({ ...this.state, tenant: e.value });
  };

  isSelected = (stageIdentifier: string): boolean => {
    return this.state.stage === stageIdentifier;
  };

  render = () => {
    return (
      <>
        <input
          type="text"
          placeholder="Tenant"
          onChange={(e) =>
            this.setState({ ...this.state, tenant: e.target.value })
          }
        />
        <GraphiQL.Select
          onSelect={(value: string) => {
            this.setState({ ...this.state, stage: value });
          }}
        >
          <GraphiQL.SelectOption
            selected={this.isSelected("s")}
            value="s"
            label="Staging"
          />
          <GraphiQL.SelectOption
            selected={this.isSelected("p")}
            value="p"
            label="Production"
          />
        </GraphiQL.Select>
        <input
          type="password"
          placeholder="Token"
          onChange={(e) =>
            this.setState({ ...this.state, token: e.target.value })
          }
        />
        <GraphiQL.Button
          label="Fetch schema"
          onClick={() => {
            const { stage, tenant, token } = this.state;

            if (stage === "" || tenant === "" || token === "") {
              window.alert("Invalid credentials or invalid stage");
              return;
            }

            this.props.fetchSchema(stage, tenant, token);
          }}
        />
      </>
    );
  };
}

export default StageTenantSelector;
